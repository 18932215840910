.ui.modal .content>.image.mta-license {
  margin-right: 1em !important;
}

.about-modal {
  &.dark {
    background-color: #1b1c1d;
    color: #ffffff;

    .header, .content {
      background-color: #1b1c1d;
      color: #ffffff;
    }

    .close.icon {
      color: #ffffff;
    }
  }
}